@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  strong {
    @apply font-bold;
  }
  u {
    @apply underline;
  }
  ul {
    @apply list-disc;
  }
  ol {
    @apply list-decimal;
  }
  h1 {
      @apply text-4xl;
  }
  h2 {
      @apply text-2xl;
  }
  h3 {
      @apply text-xl;
  }
  em {
    @apply italic;
  }
}
.ql-indent-1 {
    @apply ml-4;
}
.ql-indent-2 {
    @apply ml-8;
}
.ql-indent-3 {
    @apply ml-12;
}